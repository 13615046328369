import axios from "axios";
import { NotificationManager } from "react-notifications";
import { LoginAction, CustomerAction } from "./ActionManager";
export default async function AxiosManager(formData, callBackFun = null) {
  JSON.stringify(formData);
  NotificationManager.info("Please wait underprocess...");
  axios({
    headers: {
      "Content-Type": "application/json",
    },
    method: "post",
    url: "https://www.bestwebdealers.com/reactManager/",
    data: formData,
  })
    .then((res) => {
      if (typeof res.data == "object") {
        return res.data;
      } else if (res.data.includes("success:")) {
        if (formData.module === "login" && res.data.includes("success:")) {
          var gotemail = "";
          if ((gotemail = LoginAction(res.data))) {
            NotificationManager.success(
              "Login Successful as " + gotemail + "..."
            );
            return true;
          }
        } else if (formData.module === "signup") {
          NotificationManager.success("Registration successful");
        }
      } else if (formData.module === "customerM") {
        CustomerAction(res.data);
        NotificationManager.success("Customer list udpated successfully");
      } else {
        NotificationManager.error(res.data);
      }
    })
    .catch((error) => console.log(error));
}
