import { BrowserRouter } from "react-router-dom";
import Pagerouting from "./internal/pages/Pagerouting.jsx";
import "./App.css";
import "./internal/css/responsive.css";

function App() {
  return (
    <BrowserRouter>
      <div className="App">
        <Pagerouting />
      </div>
    </BrowserRouter>
  );
}

export default App;
