import React, { Component } from "react";
import { Link } from "react-router-dom";

export default class InvalidAccess extends Component {
  render() {
    return (
      <div>
        <>
          <h2>Invalid Access</h2> <Link to="../logout">click here</Link> to
          login again
        </>
      </div>
    );
  }
}
