import { useEffect, useState } from "react";
import Dashboard from "./Dashboard";
import { NotificationManager } from "react-notifications";
import axios from "axios";
if (localStorage.getItem("user") === null) {
  window.location.href = "../";
}
export default function Profile() {
  const [getProfile, setgetProfile] = useState({
    id: localStorage.getItem("user"),
    module: "getProfile",
    product: "quotationB",
  });
  async function AxiosQuotes(formData) {
    NotificationManager.info("please wait...");
    JSON.stringify(formData);
    axios({
      method: "post",
      url: "https://www.bestwebdealers.com/reactManager/",
      data: formData,
    }).then((res) => {});
  }
  useEffect(() => {
    AxiosQuotes(getProfile);
  });
  return (
    <div>
      <Dashboard />
      <div id="content_area">
        <form method="post">
          <input type="hidden" value="0" name="id" />
          <input type="text" value={"Best Web Dealers"} disabled readOnly />
        </form>
      </div>
    </div>
  );
}
