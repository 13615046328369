import React, { Component } from "react";
import { Link } from "react-router-dom";
import LoginLeft from "./LoginLeft";

export default class ResetPassword extends Component {
  render() {
    return (
      <div className="login-section">
        <LoginLeft />
        <div className="login-right">
          <p className="App-Heading">RESET PASSWORD</p>
          <form method="post" autoComplete="off">
            <input
              type="text"
              name="user"
              placeholder="Registered User ID / Email Id"
              required
            />
            <br />
            <input type="submit" value="Send&rArr;" />
          </form>
          <br />
          <br />
          <small>
            *you will get an email with link to reset Password
            <br />
            if you've not received any email, please try checking your spam
            folder.
          </small>
          <br />
          <br />
          <Link to="../login">Login</Link> |{" "}
          <Link to="../sign-up">Sign Up</Link>
        </div>
      </div>
    );
  }
}
