import React, { useState } from "react";
import { Link } from "react-router-dom";
import LoginLeft from "./LoginLeft";
import AxiosManager from "./AxiosManager";
import { NotificationManager } from "react-notifications";
export default function Signup() {
  const [formData, setFormData] = useState({
    business: "",
    name: "",
    email: "",
    password: "",
    module: "signup",
    product: "quotationB",
    repassword: "",
    toberemoved: "",
  });

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };
  const handleSubmit = (event) => {
    event.preventDefault();
    if (formData.password !== formData.repassword) {
      NotificationManager.error(
        "mismatch of password and confirmation password"
      );
    } else {
      setFormData({
        ...formData,
        ["toberemoved"]: "repassword",
      });

      NotificationManager.info("request initiated");
      AxiosManager(formData);
    }
  };
  return (
    <div className="login-section">
      <LoginLeft />
      <div className="login-right">
        <p className="App-Heading">Signup</p>
        <form method="post" autoComplete="off" onSubmit={handleSubmit}>
          {/* <input
              type="text"
              name="corporate*"
              placeholder="Corporate ID*"
              required
            /> */}
          <input
            type="text"
            name="business"
            placeholder="Business Name*"
            required
            onChange={handleInputChange}
          />
          <br />
          <input
            type="text"
            name="name"
            placeholder="Full Name*"
            required
            min="4"
            onChange={handleInputChange}
          />
          <input
            type="email"
            name="email"
            placeholder="info@bestwebdealers.com"
            required
            onChange={handleInputChange}
          />
          <br />
          <input
            type="password"
            placeholder="password"
            name="password"
            required
            onChange={handleInputChange}
          />
          <br />
          <input
            type="password"
            placeholder="reconfirm password"
            name="repassword"
            required
            onChange={handleInputChange}
          />
          <br />
          <input type="submit" value="Signup&rArr;" />
        </form>
        Already have an account <Link to="../login">Login </Link>
      </div>
    </div>
  );
}
