import React, { useEffect, useState } from "react";
import { Link, Navigate, useNavigate } from "react-router-dom";
import LoginLeft from "./LoginLeft";
import AxiosManager from "./AxiosManager";

export default function Login() {
  const Navigate = useNavigate();
  const [formData, setFormData] = useState({
    business: "",
    email: "",
    password: "",
    module: "login",
    product: "quotationB",
    toberemoved: "",
  });

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };
  const handleSubmit = (event) => {
    event.preventDefault();
    AxiosManager(formData).then(() => {
      setTimeout(() => {
        if (localStorage.getItem("user_email")) {
          Navigate("../office");
        }
      }, 3000);
    });
  };
  if (localStorage.getItem("user_email")) {
    console.log(localStorage.getItem("user_email"));
    Navigate("../office");
  }
  return (
    <div className="login-section">
      <LoginLeft />
      <div className="login-right">
        <p className="App-Heading">LOGIN</p>
        <form method="post" autoComplete="off" onSubmit={handleSubmit}>
          <input
            type="text"
            name="business"
            placeholder="Corporate ID*"
            required
            onChange={handleInputChange}
          />
          <br />
          <input
            type="email"
            onChange={handleInputChange}
            name="email"
            placeholder="Email Id*"
            required
          />
          <br />
          <input
            type="password"
            placeholder="*******"
            onChange={handleInputChange}
            required
            name="password"
          />
          <br />
          <input type="submit" value="Login&rArr;" />
        </form>
        Don't have an account? <Link to="../sign-up">Signup </Link>
        <br />
        Forget Password? <Link to="../reset-password">Reset Password </Link>
      </div>
    </div>
  );
}
