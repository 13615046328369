const splitter = "!@#$";
export function LoginAction(Drceived) {
  var makevars = Drceived.replace("success: ", "").split(splitter);

  localStorage.setItem("user", makevars[0]);
  localStorage.setItem("business", makevars[5]);
  localStorage.setItem("business_id", makevars[1]);
  localStorage.setItem("user_rights", makevars[2]);
  localStorage.setItem("user_name", makevars[3]);
  localStorage.setItem("user_email", makevars[4]);
  return localStorage.getItem("user_email");
}
export function CustomerAction(Drceived) {}
export default function ActionManager() {}
