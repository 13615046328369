import axios from "axios";
import Dashboard from "./Dashboard";
import React, { useState, useEffect } from "react";
//import NotificationManager from "react-notifications";
import InvalidAccess from "./InvalidAccess";
export default function Products() {
  const [ProductItem, setProductItem] = useState([
    {
      id: 0,
      product_name: "",
      manufacture: "",
      sku: "",
      min_price: 0,
      max_price: 0,
    },
  ]);
  const getProduct = {
    corp_id: localStorage.getItem("business_id"),
    module: "grabItems",
    product: "quotationB",
  };

  const [updateProduct, setupdateProducts] = useState({
    corp_id: localStorage.getItem("business_id"),
    user_id: localStorage.getItem("user"),
    user_ryt: localStorage.getItem("user_rights"),
    id: 0,
    max_price: 0,
    min_price: 0,
    module: "updateItem",
    product: "quotationB",
  });

  const [addProduct, setaddProduct] = useState({
    corp_id: localStorage.getItem("business_id"),
    user_id: localStorage.getItem("user"),
    user_ryt: localStorage.getItem("user_rights"),
    product_name: "",
    manufacture: "",
    sku: "",
    module: "addItem",
    product: "quotationB",
  });
  async function AxiosProducts(formData) {
    //NotificationManager.info("please wait...");
    JSON.stringify(formData);
    axios({
      method: "post",
      url: "https://www.bestwebdealers.com/reactManager/",
      data: formData,
    }).then((res) => {
      if (formData.module === "addItem") {
        AxiosProducts(getProduct);
      }
      if (formData.module === "grabItems") {
        setProductItem(res.data.data);
      }
      if (formData.module === "updateItem") {
        const prods = ProductItem.map((prod) => {
          return prod.id == formData.id
            ? {
                ...prod,
                ["min_price"]: formData.min_price,
                ["max_price"]: formData.max_price,
              }
            : { ...prod };
        });
        setProductItem(prods);
      }
    });
  }
  const handleInputChangeAdd = (event) => {
    const { name, value } = event.target;
    setaddProduct({
      ...addProduct,
      [name]: value,
    });
  };
  const handleInputChange = (event) => {
    const { name, value } = event.target;
    if (name == "id") {
      const udtFld = ProductItem.find((prod) => {
        return prod.id === value;
      });
      setupdateProducts({
        ...updateProduct,
        ...udtFld,
      });
    } else {
      setupdateProducts({
        ...updateProduct,
        [name]: value,
      });
    }
  };
  const handleAddition = (event) => {
    event.preventDefault();
    AxiosProducts(addProduct);
  };
  const handleSubmit = (event) => {
    event.preventDefault();
    AxiosProducts(updateProduct);
  };
  useEffect(() => {
    AxiosProducts(getProduct);
  }, []);
  if (
    localStorage.getItem("user") === 20000 ||
    localStorage.getItem("user") == ""
  ) {
    return <InvalidAccess />;
  }
  return (
    <div>
      <Dashboard />
      <div id="content_area">
        <h2>Products Manager</h2>
        <h3>Add Products</h3>
        <form onSubmit={handleAddition}>
          <input
            type="text"
            required
            name="product_name"
            placeholder="Product Name"
            onChange={handleInputChangeAdd}
          />
          <input
            type="text"
            required
            name="manufacture"
            placeholder="Brand Name"
            onChange={handleInputChangeAdd}
          />

          <input onChange={handleInputChangeAdd} type="text" name="tax" placeholder="GST Percentage (number only i.e. 18)"  required />
          <input
            type="text"
            required
            name="sku"
            placeholder="Product SKU"
            onChange={handleInputChangeAdd}
          />
          <input type="submit" value={"Add new product"} />
        </form>
        <div className="clear">
          <br />
        </div>
        <h3>Manage Pricing</h3>
        <form onSubmit={handleSubmit}>
          <select
            multiple
            name="id"
            defaultValue={updateProduct.id}
            onChange={handleInputChange}
          >
            {ProductItem.map((item) => {
              return (
                <option key={item.id} value={item.id}>
                  {item.product_name +
                    " ( " +
                    item.manufacture +
                    " , " +
                    item.sku +
                    " ) "}
                </option>
              );
            })}
          </select>
          <input
            type="text"
            placeholder="Minimum Price"
            name="min_price"
            onChange={handleInputChange}
            value={updateProduct.min_price}
          />
          <input
            type="text"
            placeholder="Maximum price"
            name="max_price"
            onChange={handleInputChange}
            value={updateProduct.max_price}
          />
          <input type="submit" name="submit" value={"Save"} />
        </form>
      </div>
    </div>
  );
}
