import { Routes, Route } from "react-router-dom";
import Login from "./Login";
import Home from "./Home";
import Signup from "./Signup";
import ResetPassword from "./ResetPassword";
import NoPage from "./NoPage";

import Products from "./office/Products";
import Quotes from "./office/Quotes";
import Profile from "./office/Profile";
import Office from "./office/Dashboard";
import Customers from "./office/Customers";
import Report from "./office/Reports";
import Logout from "./office/Logout";
import Pdf from "./office/Pdf";

export default function Pagerouting() {
  return (
    <>
      <Routes>
        <Route path="login" element={<Login />} />
        <Route path="sign-up" element={<Signup />} />
        <Route path="office" element={<Office />} />
        <Route path="quotes" element={<Quotes />} />
        <Route path="customers" element={<Customers />} />
        <Route path="reports" element={<Report />} />
        <Route path={"pdf"} element={<Pdf />} />
        <Route path={"products"} element={<Products />} />
        <Route path="logout" element={<Logout />} />
        <Route path="profile" element={<Profile />} />
        <Route index element={<Home />} />
        <Route path="reset-password" element={<ResetPassword />} />
        <Route path="*" element={<NoPage />} />
      </Routes>
    </>
  );
}
