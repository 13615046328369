import React, { Component } from "react";
import { Link } from "react-router-dom";
import logo from "../images/logo-quotation-builder.png";

export default function Home() {
  if (
    localStorage.getItem("user") === undefined ||
    localStorage.getItem("user") === "" ||
    localStorage.getItem("user") === null
  ) {
    localStorage.setItem("user", "20000");
  }
  console.log(localStorage.getItem("user"));
  return (
    <div>
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p className="App-Heading">QUOTATION BUILDER</p>
        <Link className="App-link" to="/login" rel="noopener noreferrer">
          Login&rArr;
        </Link>
      </header>
    </div>
  );
}
