import React, { Component } from "react";
import logo from "../images/logo-quotation-builder.png";
import "../css/login.css";
import "react-notifications/lib/notifications.css";
import { NotificationContainer } from "react-notifications";

export default class LoginLeft extends Component {
  render() {
    return (
      <div className="login-left">
        <NotificationContainer />
        <img src={logo} className="App-logo" alt="logo" />
        <p className="App-Heading">QUOTATION BUILDER</p>
      </div>
    );
  }
}
