import { useState, useEffect } from "react";
import Dashboard from "./Dashboard";
import { NotificationManager } from "react-notifications";
import axios from "axios";
import React from "react";
import InvalidAccess from "./InvalidAccess";
import { toast } from "react-toastify";


// import 'react-select/dist/react-select.css'
// import 'react-virtualized/styles.css'
// import 'react-virtualized-select/styles.css'
// import VirtualizedSelect from 'react-virtualized-select'

export default function Quotes() {

  if (localStorage.getItem("user") === null) {
    window.location.href = "../";
  }
  const ryts = localStorage.getItem("user_rights");
  const [filtered, setFiltered] = useState("");
  const spltr = "!@$";
  const corp_id = localStorage.getItem("business_id");
  const user_id = localStorage.getItem("user");
  const [ProductItem, setProductItem] = useState([
    {
      id: 0,
      product_name: "",
      manufacture: "",
      sku: "",
      min_price: 0,
      tax: 0,
      qty: 0,
      max_price: 0,
    },
  ]);
  const InitialProductField = [
    {
      id: 0,
      sku: "",
      product_name: "",
      product_id: 0,
      qty: 1,
      min_price: 0,
      tax: 0,
      price: 0,
      pro_description: "",
    },
  ];
  const [ProductField, setProductField] = useState([
    {
      id: 0,
      sku: "",
      product_name: "",
      qty: 1,
      product_id: 0,
      min_price: 0,
      price: 0,
      pro_description: "",

    },
  ]);
const handleFilter = (event)=>{
    const { name, value } = event.target;
    setFiltered(value);
}
  const addNewProduct = (event) => {
    event.preventDefault();
    const newList = ProductField.concat(InitialProductField[0]);
    setProductField(newList);
  };

  const getProduct = {
    corp_id: corp_id,
    module: "grabItems",
    product: "quotationB",
  };

  //start customer controls
  const [cusList, setCustomerList] = useState([
    {
      id: "0",
      company: "",
      name: "",
      user_id: "",
    },
  ]);
  const getCustomer = {
    cusID: 0,
    corp_id: corp_id,
    module: "getCustomer",
    product: "quotationB",
  };
  //end customer controls

  //start quote controls
  const quoteInitial = {
    id: 0,
    name: "",
    corp_id: corp_id,
    user_id: user_id,
    customer: "0",
    creator_id: user_id,
    comment: "",
    module: "quoteM",
    product: "quotationB",
    quoteItem: [{ id: 0, qty: 1, price: 0,sku:"",manufacture:"",max_price:0, min_price:0, pro_description:"", product_id:0,product_name:"" }],
  };
  const [CurrentQuote, setCurrentQuote] = useState({
    id: 0,
    name: "",
    withtax:1,
    corp_id: corp_id,
    user_id: user_id,
    customer: "0",
    creator_id: user_id,
    comment: "",
    module: "quoteM",
    product: "quotationB",
    quoteItem: [{ id: 0, qty: 1, price: 0,sku:"",manufacture:"",max_price:0, min_price:0, pro_description:"", product_id:0,product_name:"" }],
    
  });

  const [quoteList, setQuoteList] = useState([
    {
      id: 0,
      name: "",
      creator_id: user_id,
      user_id: user_id,
    },
  ]);

  const resetForm = () => {
    setCurrentQuote(quoteInitial);
    setProductField(InitialProductField);
  };

  const [getQuotes, setGetQuotes] = useState({
    id: 0,
    user_id: user_id,
    corp_id: corp_id,
    module: "getQuote",
    product: "quotationB",
  });
  //end quote controls
const clear = (event) => {
    const crntfld = event.target.name.split(spltr);
    // ProductField[crntfld[1]].sku=""
    //  event.target.value = "";
  };

  async function AxiosQuotes(formData) {
    NotificationManager.info("please wait...");
    JSON.stringify(formData);
    axios({
      method: "post",
      url: "https://www.bestwebdealers.com/reactManager/",
      data: formData,
    }).then((res) => {
        if (formData.module === "getCustomer") {
          if (formData.cusID === 0) {
            setCustomerList(res.data.data);
          }
        }
        if (formData.module === "grabItems") {
          setProductItem(res.data.data);
        }
        if (formData.module === "getQuote") {
          if (res.data.data != undefined) {
            if (formData.id === 0) {
              setQuoteList(res.data.data);
            } else {
              setCurrentQuote({ ...CurrentQuote, ...res.data.data[0] });
              if (
                res.data.data[0].quoteItem != undefined &&
                res.data.data[0].quoteItem != null
              ) {
                setProductField(res.data.data[0].quoteItem);
              } else {
                setProductField(InitialProductField);
              }
            }
            toast.success("ready to go");
          }else{
            toast.warning("no-data/invalid request");
          }
        }
        if (formData.module === "quoteM") {
          handleExistingCustomer(res.data);
          toast.info("done!");
        }
      }
    );
  }
  useEffect(() => {
    AxiosQuotes(getCustomer);
    AxiosQuotes(getQuotes);
    AxiosQuotes(getProduct);
  }, [getQuotes]);

  
  const handleChange = (event) => {
    const { name, value } = event.target;
    if (name.includes("quoteItem" + spltr)) {
      const crntfld = name.split(spltr);
      console.log(event.nativeEvent.inputType);
      if ( (event.nativeEvent.inputType=="insertReplacementText" || event.nativeEvent.inputType==undefined) &&  crntfld[2] === "sku") {
        const selProdDetail = ProductItem.find((prod) => {
          return prod.sku === value;
        });
        if (selProdDetail !== undefined) {
          const udtProdField = ProductField.map((pros, index) => {
            return index == crntfld[1]
              ? {
                  ...pros,
                  ...selProdDetail,
                  ["price"]: selProdDetail.max_price,
                  ["product_id"]: selProdDetail.id,
                  ["id"]: pros.id,
                  ["sku"]: value,
                }
              : { ...pros };
          });
          console.log(udtProdField);
          setCurrentQuote({
            ...CurrentQuote,
            ["quoteItem"]: [...udtProdField],
          });
          setProductField(udtProdField);
        }
      } else {
        const udtProdField = ProductField.map((pros, index) => {
          return index == crntfld[1]
            ?  { ...pros, [crntfld[2]]: value }
            : { ...pros }  ;
        });
        
        setCurrentQuote({
          ...CurrentQuote,
          ["quoteItem"]: [...udtProdField],
        });
        setProductField(udtProdField);
        
      }
    } else {
      setCurrentQuote({
        ...CurrentQuote,
        [name]: value,
      });

    }
  };
  const handleCheckbox = (event) =>{
    const check = (event.target.checked) ? 1 : 0;
    setCurrentQuote({...CurrentQuote,['withtax']:check})
  }
  const handleSubmit = (event) => {
    event.preventDefault();
    if (
      CurrentQuote.id === 0 ||
      user_id === CurrentQuote.user_id ||
      user_id === CurrentQuote.creator_id
    ) {
      const commUdt = CurrentQuote.comment.replace(/\n/g, "<br />");
      setCurrentQuote({ ...CurrentQuote, ["comment"]: commUdt });
      AxiosQuotes({ ...CurrentQuote });
    }
  };
  const generatePDF = (pdfID) => {
    localStorage.setItem("generatePDF", pdfID);
    window.open("../pdf");
  };
  const handleExistingCustomer = (quoteId) => {
    setGetQuotes({ ...getQuotes, ["id"]: quoteId });
    AxiosQuotes(getQuotes);
  };
  if (
    localStorage.getItem("user") == 20000 ||
    localStorage.getItem("user") == ""
  ) {
    return <InvalidAccess />;
  }
  return (
    <>
      <Dashboard />
      <div id="content_area">
        <div id="list_section">
          <h2>Existing Quotes</h2>
          <h3><form style={{float:'none'}}>
            <input type="text" onChange={handleFilter} placeholder="filter list by name"  value={filtered}  />
          </form></h3>
          <div>
            <ul>
              {quoteList.filter(function(d){ return d.name.toLowerCase().indexOf(filtered.toLowerCase())!=-1}).map((qt) => {
                return qt.name != "" ? (
                  <li key={qt.id}>
                    <p>{qt.name}</p>
                    <button onClick={() => handleExistingCustomer(qt.id)}>
                      View
                    </button>
                  </li>
                ) : (
                  ""
                );
              })}
            </ul>
          </div>
        </div>

        <form method="post" onSubmit={handleSubmit}>
          <input type="hidden" value={CurrentQuote.id} name="id" />
          <input onClick={resetForm} type="reset" value="Add new quote" />
          <h2>
            Quotation Manager{" "}
            {CurrentQuote.id !== 0 ? (
              <button onClick={() => generatePDF(CurrentQuote.id)}>
                Generate PDF
              </button>
            ) : (
              ""
            )}
          </h2>
          with tax? 
          <input onChange={handleCheckbox} checked={CurrentQuote.withtax==1 ? true : false } style={{display:"inline", width:"30px"}} type="checkbox" value={'1'} name="withtax"  />
          <select
            onChange={handleChange}
            required
            name="customer"
            value={CurrentQuote.customer}
          >
            <option value=""> - - Customer List - - </option>
            {cusList.map((cus) => {
                return <option key={cus.id} value={cus.id}>
                  {cus.company} ({cus.name})
                </option>
            })}
          </select>
          <input
            type="text"
            placeholder="Quote Name"
            value={CurrentQuote.name}
            name="name"
            onChange={handleChange}
            required
          />
          <textarea
            name="comment"
            placeholder="Terms and Conditions"
            defaultValue={CurrentQuote.comment}
            onChange={handleChange}
          ></textarea>
          <ul id="productsList">
            <li>
              <p className="productListTitle" id="productList">
                Product Name
              </p>
              <p className="productListTitle" id="qtyList">
                Qty
              </p>
              <p className="productListTitle" id="priceList">
                Price
              </p>
            </li>
            {ProductField.map((productF, index) => {
              productF.min_price = (ryts == "1") ? 0 : productF.min_price ;
              return (
                <li key={index}>
                  <input 
                    type="hidden"
                    name={"quoteItem" + spltr + index + spltr + "id"}
                    value={productF.id ? productF.id : 0}
                  />
                  <input autoComplete="off" required style={{width: '42%', float: 'left'}}
                    value={productF.sku }
                    onClick={clear}
                    onFocus={clear}
                    onChange ={handleChange}
                    // onSelect={handleChange}
                    name={"quoteItem" + spltr + index + spltr + "sku"} 
                    placeholder="enter product name"
                    type="list"
                    list={"quoteList" + spltr + index + spltr + "product_sku"} />
                  <datalist  id={"quoteList" + spltr + index + spltr + "product_sku"}>
                    {ProductItem.map((item) => {
                      return (
                        <option key={item.id} value={item.sku}>
                          {item.product_name +
                            " ( " +
                            item.manufacture +
                            " , " +
                            item.sku +
                            " ) "}
                        </option>
                      );
                    })}
                  </datalist>
                  <input
                    type="number"
                    id="qtyList"
                    name={"quoteItem" + spltr + index + spltr + "qty"}
                    value={productF.qty}
                    min="0"
                    onChange={handleChange}
                  />
                  <input
                    type="number"
                    onChange={handleChange}
                    id="priceList"
                    min={productF.min_price}
                    name={"quoteItem" + spltr + index + spltr + "price"}
                    value={productF.price}
                  />
                  <input
                    type="text"
                    onChange={handleChange}
                    id="pro_description"
                    min={productF.pro_description}
                    name={
                      "quoteItem" + spltr + index + spltr + "pro_description"
                    }
                    value={productF.pro_description}
                    placeholder="Product Description"
                  />
                  <div className="clear textRight italic">
                    <small>min price: {productF.min_price}</small>
                  </div>
                  <hr />
                </li>
              );
            })}

            <li>
              <button onClick={addNewProduct}>Add new product</button>
            </li>
          </ul>
          <input type="submit" name="submit" value="Save Quote" />
        </form>
      </div>
    </>
  );
}
